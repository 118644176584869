import React from "react"
import * as cardStyle from "./workCard.module.scss"
import { Link } from "gatsby"
import { Button } from "@mui/material"
import ArrowRightAltSharpIcon from "@mui/icons-material/ArrowRightAltSharp"

import phone from "../../images/pngegg.png"


const WorkCard = props => {
  return (
    <div className={cardStyle.card}>
      <div className={cardStyle.box}>
        <img src={phone} al="neoxr over layer phone" className={cardStyle.phone}></img>
        <div className={cardStyle.videoCard}>
          <video
            className={cardStyle.video}
            muted
            loop
            autoPlay
            playsInline
            src={props.image}
          ></video>
        </div>
        <div className={cardStyle.title}>{props.title}</div>
        <Link to={`/contact`}>
        <Button
          variant="outlined"
          sx={{
            color: "#0E63CA",
            borderRadius: 8,
            margin: "0 10px",
            fontSize: "0.8rem",
            alignSelf: "start",
            fontWeight: "600",
            textTransform: "capitalize",
          }}
        >
          Learn More 
          <ArrowRightAltSharpIcon
            sx={{
              marginLeft: "5px",
            }}
          ></ArrowRightAltSharpIcon>

        </Button>
        </Link>
      </div>
    </div>
  )
}

export default WorkCard
