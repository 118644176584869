import React from "react"
import Layout from "../components/layout"

import Header from "../components/home/header"
import WhoWeHelpSection from "../components/home/whoWeHelpSection"
import WorksSection from "../components/home/worksSection"
import Testimonial from "../components/home/testimonial"
import FAQSection from "../components/home/FAQSection"
import VistorSection from "../components/home/vistorSection"
import CompaniesSection from "../components/home/companiesSection"
import ContactSection from "../components/home/ContactSection"

import {useStaticQuery, graphql } from 'gatsby'

import {Helmet} from "react-helmet";


const Index = () => {


  const articleStructuredData = {
    "@context":"http://schema.org/",
    "@type":"3DModel",
    "image":"https://neoxr.s3.dualstack.us-east-2.amazonaws.com/webassets/poster.png",
    "name":"NeoXR webAR Bike",
    "encoding":[ 
       { 
          "@type":"MediaObject",
          "contentUrl":"https://neoxr.s3.dualstack.us-east-2.amazonaws.com/webassets/bikeA9.glb",
          "encodingFormat":"model/gltf-binary"
       },
       { 
          "@type":"MediaObject",
          "contentUrl":"https://neoxr.s3.dualstack.us-east-2.amazonaws.com/webassets/bikeA9.usdz",
          "encodingFormat":"model/vnd.usdz+zip"
       }
    ]
  };


  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`)

  return (
    <Layout title={data.site.siteMetadata.title} mode="home">
      <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(articleStructuredData)}
      </script>
      </Helmet>
      <Header></Header>
      <VistorSection></VistorSection>
      <WhoWeHelpSection></WhoWeHelpSection>
      <WorksSection></WorksSection>
      <Testimonial></Testimonial>
      <CompaniesSection></CompaniesSection>
      <FAQSection></FAQSection>

      <ContactSection></ContactSection>
    </Layout>
  )
}

export default Index
