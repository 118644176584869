import React from "react"
import { Typography, Container, Grid } from "@mui/material"

import zappar from "./companies/zappar-ltd-logo-vector.png"
import aws from "./companies/amazonWebservices_Logo.png"
import eightthwall from "./companies/8th-wall_owler.png"
import amazon from "./companies/Amazon_logo.png"
import bluey from "./companies/bluey.png"
import frootloops from "./companies/frootloops.png"
import lego from "./companies/lego.png"
import mcdonals from "./companies/McDonalds.png"
import natm from "./companies/natm.png"
import vodafone from "./companies/vodafone-logo.jpg"
import chapter2 from "./companies/chapter2.png"
import volvo from "./companies/volvo_logo.png"


// natm
const companyData = [
  // {
  //   logo: amazon,
  //   alt: "amazon logo",
  // },
  
  {
    logo: natm,
    alt: "national atomic testing museum",
  },
  // {
  //   logo: mcdonals,
  //   alt: "mcdonals logo",
  // },
  {
    logo: bluey,
    alt: "bluey logo",
  },
  {
    logo: lego,
    alt: "lego logo",
  },  {
    logo: frootloops,
    alt: "frootloops logo",
  },
  {
    logo: vodafone,
    alt: "vodafone logo",
  },
  {
    logo:   volvo,
    alt: "volvo logo",
  },
  {
    logo: chapter2,
    alt: "chapter2 logo",
  },
  // {
  //   logo: aws,
  //   alt: "aws logo",
  // },
  {
    logo: zappar,
    alt: "zappar logo",
  },

  {
    logo: eightthwall,
    alt: "8th wall logo",
  },

]

const CompaniesSection = () => {
  return (
    <Container>
      <Typography
        variant="h2"
        component="h2"
        sx={{
          fontFamily: "Archivo Black, sans-serif",
          fontSize: { xs: "1.5rem", md: "2rem" },
          color: "black",
          textAlign: "center",
          lineHeight: "100%",
          maxWidth: "750px",
          margin: "50px auto",
        }}
      >
        We have worked with world's Leading Companies
      </Typography>
      <Grid
        container
        spacing={0}
        sx={{
        //   filter: "grayscale(1)",
          justifyContent: "center",
          padding: "10px 10px",
        }}
      >
        {companyData.map((data, index) => (
          <Grid
            key={index}
            item
            xs={3}
            md={3}
            sx={{
              padding:{xs:"10px 5px", md:"20px 50px"},
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <img style={{ width: "100%" }} src={data.logo} alt={data.alt}></img>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default CompaniesSection
